/*
 * card style
 */
@import "color";

.card{
  background-color: white;
  margin:1rem 0 1.5rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  .card-header{
    padding: 1.5rem 1rem 0.5rem;
    font-size: 1.2rem;
    border-bottom: 1px solid $primary-color;
    font-weight: bold;
    span{
      display: block;
      padding: 0 1rem;
      font-size: 0.8rem;
      color: $primary-color;
    }
  }
  .card-body{
    padding: 0.5rem;
  }
  .card-footer{
    padding:1rem;
  }
  a{
    text-decoration: none;
    color: $info-color;
    &:hover{
      color: $primary-color;
    }
  }
}

.profile-card{
  height: 21.5rem;
  border-radius: 10px;
  text-align: center;
  overflow: hidden;
  min-width: 12rem;

  .profile-header{
    background: url("https://d2ik0zjyew1kf4.cloudfront.net/marketting/AdobeStock_71313802.jpeg") center no-repeat;
    background-size: cover;
    height: 8rem;

    .profile-pic{
      img{
        border-radius: 50%;
        height: 5rem;
        position: relative;
        top: 4.5rem;
      }
    }
  }

  .profile-body{
    .profile-name{
      margin: 1.5rem auto 0;
      font-size: 1.2rem;
      font-weight: 400;
    }
    .profile-username{
      font-family: letter-gothic-std,monospace;
      font-weight: 400;
      font-style: italic;
      font-size: 1rem;
    }
  }

  .profile-footer{
    background-color: $logo-color;
    color: $default-color;
    position: relative;
    top: 4rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 5rem;

    div{
      font-size: 14px;
      span{
        display: block;
        font-size: 11px;
        color: $default-color;
      }
    }
  }
}

.rating-card{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  .rating-pic{
    margin: 1rem;
    img{
      border-radius: 50%;
      height: 3rem;
    }
  }
  .rating-content{
    margin: 0 auto;
    padding: 0.5rem 1rem;
    .bar-hold{
      height: 1.2rem;
      width: 100%;
      background-color: $light-color;

      .bar-rating{
        height: 1rem;
        background-color: $secondary-color;
      }
    }
  }
}

.profileHome-card {
  height: 16rem;
  border-radius: 10px;
  text-align: left;
  overflow: hidden;
  min-width: 12rem;

  .profileHome-header {
    background: url("https://d2ik0zjyew1kf4.cloudfront.net/marketting/AdobeStock_71313802.jpeg") center no-repeat;
    background-size: cover;
    height: 10rem;

    .profileHome-pic {
      img {
        border-radius: 50%;
        height: 8rem;
        position: relative;
        top: 7rem;
        margin-left: 2rem;
        border: 8px solid #ffffff;
      }
    }
  }

  .profileHome-body{
    margin-left: 11rem;
    display: flex;
    justify-content: space-between;
    .profile-name{
      margin: 1.5rem auto 0;
      font-size: 1.3rem;
      font-weight: bold;
    }
    .profile-username{
      font-family: letter-gothic-std,monospace;
      font-weight: 400;
      font-style: italic;
      font-size: 1rem;
    }
    a{
      text-decoration: none;
      margin: 2rem 1rem;
    }
  }
}