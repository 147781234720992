@import "color";

/* general */

* {
  box-sizing: border-box;

  &:before,
  &:after {
    box-sizing: border-box;
  }
}

/*
divider
 */
.vertical-divider{
  width: 2px;
  margin: 1px 0;
  background-color: #ffc137;
}

/*
** logo
 */
.logo-welcome{
  text-transform: uppercase;
  letter-spacing: 0.5rem;
  font-size: 2rem;
  color: $primary-color;
}
.logo{
  text-transform: uppercase;
  letter-spacing: 0.5rem;
  font-size: 1.5rem;
  color: $primary-color;
}

/*
** default content
 */
#content{
  position: absolute;
  margin-top: 7rem;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: space-around;
  align-content: center;
  div{
    padding: 0.1rem;
  }
  /*desktop view */
  @media screen and (min-device-width: 1025px) {
    &>div:nth-child(1){
      width: 16rem;
    }
    &>div:nth-child(3){
      width: 20rem;
    }
    &>div:nth-child(even){
      width: 32rem;
    }
  }
  /*Tablet view */
  @media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    div{
      width: 50%;
    }
  }
  /*mobile view */
  @media screen and (max-device-width: 640px) {
    flex-direction: column;
    align-content: center;
    justify-content: center;
    &>div{
      width: 100%;
    }
  }

}